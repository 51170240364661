import type { ApolloClient as ApolloClientBase } from '@apollo/client';
import { ApolloLink } from '@apollo/client';
import { ApolloClient, InMemoryCache } from '@apollo/client-integration-nextjs';

import { type GQLClientProps } from './client-props';
import { getHttpLink, getLoggerLink, persistedQueryLink, retryLink } from './links';

export type ApolloGQLClient = ApolloClientBase<unknown>;

export const makeApolloClient = (clientProps: GQLClientProps) => {
  const httpLink = getHttpLink(clientProps);
  const loggerLink = getLoggerLink(clientProps.requestId);

  return new ApolloClient({
    cache: new InMemoryCache(),
    link: ApolloLink.from([loggerLink, retryLink, persistedQueryLink.concat(httpLink)]),
    defaultOptions: {
      watchQuery: {
        fetchPolicy: 'no-cache',
        errorPolicy: 'all',
      },
      query: {
        fetchPolicy: 'no-cache',
        errorPolicy: 'all',
      },
      mutate: {
        fetchPolicy: 'no-cache',
        errorPolicy: 'all',
      },
    },
  });
};
