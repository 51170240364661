import { LoggerLevel, Stage } from 'corgi-types';
import type { TransportSingleOptions } from 'pino';
import pino from 'pino';

import { defaultTransportOptions } from '@constants/logger';
import { getEnv } from './env';

const name = process.env.npm_package_name;
const version = process.env.npm_package_version;

const getLoggerLevel = () => {
  const logLevel = getEnv<LoggerLevel>('LOG_LEVEL', LoggerLevel.Debug);

  if (Object.values(LoggerLevel).includes(logLevel)) {
    return logLevel;
  }

  throw new ReferenceError(`LOG_LEVEL not found for logger instance: ${logLevel}`);
};

export const getTransportOptions = (): undefined | TransportSingleOptions =>
  getEnv<Stage>('STAGE') === Stage.Development ? defaultTransportOptions : undefined;

export const loggerLevel = getLoggerLevel();

export const logger = pino({
  level: loggerLevel,
  name: `${name} v${version}, ${getEnv('STAGE')}, Node/Next.js`,
  transport: getTransportOptions(),
});
