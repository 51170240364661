'use client';

import { ApolloNextAppProvider } from '@apollo/client-integration-nextjs';

import { makeApolloClient } from '@graphql/client';
import type { GQLClientProps } from '@graphql/client-props';

interface Props {
  gqlClientProps: GQLClientProps;
  children: React.ReactNode;
}

const ApolloWrapper = ({ gqlClientProps, children }: Props) => (
  <ApolloNextAppProvider makeClient={() => makeApolloClient(gqlClientProps)}>
    {children}
  </ApolloNextAppProvider>
);

export default ApolloWrapper;
