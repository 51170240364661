import { ApolloLink, HttpLink } from '@apollo/client';
import { createPersistedQueryLink } from '@apollo/client/link/persisted-queries';
import { RetryLink } from '@apollo/client/link/retry';
import { getGQLClientUri } from 'frontend-utils';
import { sha256 } from 'js-sha256';

import { getEnv } from '@utils/env';
import { logger } from '@utils/logger';
import { start } from '@utils/timer';
import type { GQLClientProps } from './client-props';

export const getHttpLink = ({ featureFlags, headers }: GQLClientProps) =>
  new HttpLink({
    uri: getGQLClientUri(getEnv('GRAPHQL_ENDPOINT_URL'), featureFlags),
    credentials: 'include',
    headers,
  });

export const getLoggerLink = (requestId: string) =>
  new ApolloLink((operation, forward) => {
    const timer = start();

    return forward
      ? forward(operation).map(result => {
          const { cached } = operation.getContext();

          logger.debug(
            { requestId },
            cached
              ? `GQL "${operation.operationName}" response retrieved from cache.`
              : `GQL "${operation.operationName}" request duration: ${timer.end()}ms.`,
          );

          return result;
        })
      : null;
  });

export const persistedQueryLink = createPersistedQueryLink({
  useGETForHashedQueries: true,
  sha256,
});

const MIN_RETRY_DELAY = 250;
export const retryLink = new RetryLink({
  delay: (retryNumber: number) => {
    const delay = Math.pow(2, retryNumber - 1) * MIN_RETRY_DELAY;
    const randomMs = delay * 0.2 * Math.random(); // 0-20% of the delay

    return delay + randomMs;
  },
  attempts: {
    max: 5,
  },
});
