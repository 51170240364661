export const start = () => {
  const startTime = performance.now();

  return {
    end: () => {
      const endTime = performance.now();
      return Math.round(endTime - startTime);
    },
  };
};
