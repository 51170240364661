import type { TransportSingleOptions } from 'pino';

/** @internal */
export const defaultTransportOptions: TransportSingleOptions = {
  target: 'pino-pretty',
  options: {
    colorize: true,
    ignore: 'pid,hostname',
  },
};
